<template>
  <div class="dialog-container">
    <div class="dialog overflow-hidden">
      <div class="header">
        <div class="flex flex-col gap-2">
          <span class="title state-title">{{ $t('archives.groups_report') }}</span>
          <p class="info state-info">
            {{ $t('reports.tables.report') }}: {{ tableName }} (<span class="color-state-blue font-SourceSansPro">{{ dates[0] }} &mdash; {{ dates[1] }}</span>)
          </p>
        </div>
        <i @click="$emit('close')" class="el-icon-close text-xl leading-7 rounded-full cursor-pointer text-mainTitleColor font-semibold bg-lightGrey-200 w-7 h-7 flex align-center justify-center"></i>
      </div>
      <div class="main">
        <div class="main__header w-full pt-2 pb-4 px-4 border-b">
          <skif-search :placeholder="$t('search')" class="w-full mr-4" v-model="filterString" @searching="searching"></skif-search>
        </div>
        <div class="main__warning">
          <el-alert type="warning" :closable="false" class="rounded-xl px-1">
            <div class="flex items-center gap-2">
              <WarningIcon />
              <p class="w-full text-xs">{{ $t('archives.warning') }}</p>
            </div>
          </el-alert>
        </div>
        <div class="main__content">
          <div v-for="(unit, idx) in filtredUnits" :key="idx"
               class="h-9 flex align-center justify-between border-t py-2 list-item">
            <div class="flex align-center justify-center gap-1">
              <AttentionIcon v-if="unit.hasStates" />
              <SuccessIcon v-else />
              <h4 class="text-sm font-semibold text-mainTitleColor px-1 text-archive">{{ unit.name }}</h4>
            </div>
            <div class="flex align-center justify-center px-4" v-if="unit.hasStates">
              <p class="text-xs italic font-semibold text-gray-500 italic-archive">{{ $t('archives.exclude') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button type="button" class="accept-btn" @click="$emit('close'), $emit('refetchReport')">{{ $t('archives.requestReport') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    WarningIcon: () => import('./assets/icons/Warning.vue'),
    SuccessIcon: () => import('./assets/icons/Success.vue'),
    AttentionIcon: () => import('./assets/icons/Attention.vue')
  },
  props: {
    units: {
      type: Array,
      default: () => []
    },

    dates: {
      type: Array,
      default: []
    },
    tableName: {
      type: String,
      default: ''
    }
  },
  computed: {
    filtredUnits() {
      return this.units.filter(unit => unit.name.toLowerCase().includes(this.filterString.toLowerCase()))
    }
  },
  data() {
    return {
      filterString: ''
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    }
  }
}
</script>
<style lang="scss" scoped>
.state-title {
  font-family: 'Source Sans Pro', serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
}

.state-info {
  font-family: 'Source Sans Pro', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #A6AEB8;
}

.dialog-container {
  background-color: #00000067;
  position: fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 90
}
.dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:50%;
  left:50%;
  transform : translate(-50%,-50%);
  color: #253545;
  width: 520px;
  height: 716px;
  background: #FFF;
  border-radius: 12px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 20px 12px 20px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #292929;
    }

    .info {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
    }

  }
  .main {
    height: 570px;
    overflow: auto;

    &__warning {
      max-height: 78px;
      padding: 8px 16px;
      margin-bottom: 20px;

      ::v-deep(.el-alert__description){
        margin: 0;
        color: #CA5F32;
      }
    }
  }

  .footer{
    padding: 5px 20px 16px 20px;
    border-top: 1px solid #CAD6DF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    width: 100%;
    background-color: #fff;

    .accept-btn {
      height: 26px;
      width: 148px;
      font-size: 12px;
      font-weight: 600;
      line-height: 19px;
      text-align: left;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3A7CCA;

      &:focus {
        outline: none;
      }
    }
   }
}

.list-item {
  padding-left: 16px;
}

.color-state-blue {
  color: #3A7CCA;
}

.text-archive {
  font-family: 'Source Sans Pro', serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
}
</style>
